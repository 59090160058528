import * as React from "react"
import { useState, useEffect } from "react"
import { graphql } from "gatsby"

import "../theme/layout.scss"
import Header from "../components/layout/HeaderLayout"
import CustomFooter from "../components/CustomFooter"
import GdprCookies from "../components/GdprCookies"
import parseDate from "../utils/parseDate"
import ReportesBg from "../assets/reportes-bg.jpg"

import NavigateNextIcon from "@material-ui/icons/NavigateNext"
import NavigateBeforeIcon from "@material-ui/icons/NavigateBefore"
import FirstPageIcon from "@material-ui/icons/FirstPage"
import LastPageIcon from "@material-ui/icons/LastPage"

import ModalSuscribe from "../components/layout/ModalSuscribre"


const ReportesTrimestrales = ({ data, props }) => {

  const reportes = data.reportesTrimestrales.edges
  const hig = reportes[0]
  const reportesItems = reportes

  //functions modal suscribe
  const [open, setOpen] = useState(false)

  function openSuscribe() {
    setOpen(true)
  }

  function closeSuscribe() {
    setOpen(false)
  }

  function getYear() {
    return new Date().getFullYear().toString()
  }


  const [totalCards, setTotalCards] = useState(reportes)


  //Pagination
  const [currentPage, setCurrentPage] = useState(1)
  const todosPerPage = 5

  const indexOfLastTodo = (currentPage * todosPerPage)
  const indexOfFirstTodo = indexOfLastTodo - todosPerPage

  const cards = totalCards.slice(indexOfFirstTodo, indexOfLastTodo)
  const totalPages = Math.ceil(totalCards.length / todosPerPage)

  const [anio, setAnio] = useState(getYear())
  const [trim, setTrim] = useState("todos")

  const getQuarter = (d: Date) => {
    var date_ = new Date(d)
    var q = [4, 1, 2, 3]
    var m = q[Math.floor(date_.getMonth() / 3)]
    var y = date_.getFullYear() - (m == 4 ? 1 : 0)
    return [y, m]
  }


  const select = (anio: string, trim: string) => {

    setAnio(anio)
    setTrim(trim)

    let filter_anio

    if (trim === "todos") {
       filter_anio = reportesItems.filter(item => {
        const year = new Date(item.node.createdAt).getFullYear().toString();
        return year == anio;
      });
    } else {
       filter_anio = reportesItems.filter(item => {
        return getQuarter(item.node.createdAt).join("Q") == anio + "Q" + trim
      })
    }
    setTotalCards(filter_anio)
  }

  useEffect(() => {
    select(getYear(), "todos")
  }, [])


  return (
    <>
      <Header openModal={openSuscribe} />

      <div className="header-section-bg">

        <div className="top-bg" style={{ backgroundImage: `url(${ReportesBg})` }}></div>

        <div className="container-sec">

          <div className="top-content">
            <div className="top-title">
              <h2>Reportes Trimestrales</h2>
            </div>
            <div className="top-select">
              <select name="" id="" onChange={(e) => {
                select(e.target.value, trim)
              }}>
                <option value="2024" selected>2024</option>
                <option value="2023">2023</option>
                <option value="2022">2022</option>
                <option value="2021">2021</option>
                {/* <option value="2020">2020</option> */}
              </select>
              <select name="" id="" onChange={(e) => {
                select(anio, e.target.value)
              }}>
                <option value="todos">Todos</option>
                <option value="1">1 Trimestre</option>
                <option value="2">2 Trimestre</option>
                <option value="3">3 Trimestre</option>
                <option value="4">4 Trimestre</option>
              </select>
            </div>
          </div>

          <div className="top-item">
            <div className="top-tag">
              <p>documento destacado</p>
            </div>
            {cards.length > 0 ? (
                <div className="top-card">
                  <p className="date">{parseDate(cards[0].node.createdAt)}</p>
                  <h2 className="title">{cards[0].node.titulo}</h2>
                  <p className="resume-content">{cards[0].node.resumen}</p>
                  <div className="item--footer">
                    <p className="autor">{cards[0].node.autor.nombre}</p>
                    <a href={"/renta-variable/" + cards[0].node.slug}>
                      <div className="link">Ver más</div>
                    </a>
                  </div>
                </div>)
              : (
                <div className="top-card">
                  <h2 className="title">No hay reportes</h2>
                </div>
              )}


          </div>

        </div>
      </div>


      <div className="wrap-content">


        {cards && cards.slice(1).map(({ node }) => {
          const { titulo, resumen, autor, slug, createdAt } = node
          return (
            <div key={createdAt} className="item">
              <p className="date">{parseDate(createdAt)}</p>
              <h2 className="title">{titulo}</h2>
              <div className="resume-content">
                {resumen}
              </div>
              <div className="item--footer">
                <p className="autor">{autor.nombre}</p>
                <a href={"/renta-variable/" + slug}>
                  <div className="link">Ver más</div>
                </a>
              </div>
            </div>
          )
        })}
        <div className="wrap-pagination">
          <div className="pagination">
            <FirstPageIcon fontSize="small" onClick={() => setCurrentPage(1)} />
            <NavigateBeforeIcon fontSize="small" onClick={() => {
              if (currentPage > 1) {
                setCurrentPage(currentPage - 1)
              }
            }} />
            <div className="text">{currentPage}</div>
            <div className="text de">de</div>
            <div className="text">{totalPages}</div>
            <NavigateNextIcon fontSize="small" onClick={() => {
              if (currentPage < totalPages) {
                setCurrentPage(currentPage + 1)
              }
            }} />
            <LastPageIcon fontSize="small" onClick={() => setCurrentPage(totalPages)} />
          </div>
        </div>
      </div>
      <CustomFooter openModal={openSuscribe} />
      <GdprCookies />
      {
        open && <ModalSuscribe closeModal={closeSuscribe} />
      }
    </>

  )
}

export default ReportesTrimestrales

export const pageQuery = graphql`
query MyQueryReporteTrimestral{

    reportesTrimestrales: allContentfulReporteTrimestral(
        sort: { fields: createdAt, order: DESC }
      ) {
        edges {
          node {
            titulo
            slug
            resumen
            autor {
              nombre
            }
            createdAt
          }
        }
      }
  }
`
